<template>
  <div class="login-wrapper">
    <div class="login-banner">
      <img src="../../assets/login.png"/>
    </div>
    <div class="login-content">
      <div>
        <h1 class="login-content-title">易行通企业ETC</h1>
        <a-form-model layout="vertical">
          <a-form-model-item label="手机号">
            <a-input
                v-model="loginObj.phone"
                placeholder="请输入手机号"
                size="large"
                style="width: 100%"
                :maxLength="11"
            >
              <a slot="suffix"
                 @click="sendCode"
                 :class="codeTime !== 60 ? 'color-gray' : 'color-blue'"
              >{{
                  codeTime === 60
                      ? "发送验证码"
                      : "验证码已发送 " + codeTime + "s"
                }}</a>
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="验证码">
            <a-input
                v-model="loginObj.code"
                placeholder="请输入验证码"
                size="large"
                style="width: 100%"
                :maxLength="4"
            />
          </a-form-model-item>
        </a-form-model>
        <div class="login-content-agreement">
          <a-checkbox @change="onChangeAgreement" :checked="loginObj.agree"
          >同意
          </a-checkbox
          >
          <a
              href="https://car-etc-oss.51etr.com/agreements/yxt-enterprise-bps-userAgreement.html" target="_blank"
          >《用户服务协议》</a
          >
          <a
              href="https://car-etc-oss.51etr.com/agreements/yxt-enterprise-bps-privacyPolicy.html" target="_blank"
          >《隐私协议》</a
          >
        </div>
        <div style="margin-top: 5vh">
          <a-button type="primary" block size="large" @click="submit"
          >登录
          </a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setLocalStorage, getLocalStorage} from "@/utils/localStorage";

export default {
  name: "index",
  data() {
    return {
      codeTime: 60,
      onCode: false, //是否发送验证码
      countDown: null,
      loginObj: {
        phone: "",
        code: "",
        agree: false,
      },
    };
  },
  methods: {
    keyupEnter() {
      document.onkeydown = e => {
        let body = document.getElementsByTagName('body')[0]
        if (e.keyCode === 13 && e.target.baseURI.match(/login/) && e.target === body) {
          console.log('enter') // match(此处应填写文件在浏览器中的地址，如 '/home/index')
          this.submit()
        }
      }
    },
    /*勾选协议*/
    onChangeAgreement(e) {
      this.loginObj.agree = e.target.checked;
    },
    /*发送验证码*/
    sendCode() {
      let self = this;
      if (this.codeTime !== 60) {
        return;
      }
      if (!this.loginObj.phone) {
        this.$message.error("请输入手机号");
        return;
      }
      if (!this.loginObj.phone || this.loginObj.phone.length !== 11) {
        this.$message.error("手机号码格式有误");
        return;
      }
      this.onCode = true;
      setLocalStorage("etc-bms-phone", self.loginObj.phone);
      this.$http
          .post("/send/verifyCodeMsg", {
            bizEnum: "DEP_LOGIN_COMPANY",
            mobile: this.loginObj.phone,
          })
          .then((res) => {
            if (res.code === 200) {
              if (this.codeTime === 60) {
                this.codeTime--;
                this.countDown = setInterval(() => {
                  this.codeTime--;
                }, 1000);
              }
            }
          });
    },
    /*点击登录*/
    submit() {
      if (!this.loginObj.phone) {
        this.$message.error("请输入手机号");
        return;
      }
      if (!this.loginObj.phone || this.loginObj.phone.length !== 11) {
        this.$message.error("手机号码格式有误");
        return;
      }
      if (!this.onCode) {
        this.$message.error("请发送验证码");
        return;
      }
      if (!this.loginObj.code) {
        this.$message.error("请输入验证码");
        return;
      }
      if (!this.loginObj.code || this.loginObj.code.length !== 4) {
        this.$message.error("验证码格式有误");
        return;
      }
      if (!this.loginObj.agree) {
        this.$message.error("请同意协议");
        return;
      }
      let self = this;
      this.$http
          .postJson("/companyUser/login", {
            mobile: this.loginObj.phone,
            verifyCode: this.loginObj.code,
          })
          .then((res) => {
            if (res.code === 200) {
              setLocalStorage("etc-bms-user", res.data);
              setLocalStorage("etc-bms-phone", self.loginObj.phone);
              self.$router.push("/");
            }
          });
    },
  },
  watch: {
    codeTime(newVal) {
      if (newVal === 0) {
        clearInterval(this.countDown);
        this.codeTime = 60;
      }
    },
  },
  created() {
    if (getLocalStorage("etc-bms-phone")) {
      this.loginObj.phone = getLocalStorage("etc-bms-phone");
      this.loginObj.agree = true;
    }
    this.keyupEnter()
  },
};
</script>

<style scoped>
.login-wrapper {
  display: flex;
  height: 100vh;
}

.login-banner {
  width: 50%;
}

.login-banner > img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.login-content {
  width: 50%;
}

.login-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-content-title {
  font-size: 44px;
  font-weight: 500;
  color: #1890ff;
  margin-bottom: 5vh;
}

.login-content-agreement {
  display: flex;
  align-items: center;
}

.color-blue {
  color: #1890ff;
}

.color-gray {
  z-index: 2;
  color: #d9d9d9;
}

.color-gray:hover {
  cursor: no-drop;

}

/* .phone-inpuy[type="number"]::-webkit-inner-spin-button,
.phone-inpuy[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
